#main-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  background-color: #0D1925;
  background-image: url("../../images/mountain.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom;
}
