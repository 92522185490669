#footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  color: #FFFFFF;
  padding: 10px 0;
  font-size: 11px;
  display: flex;
  justify-content: center;
  align-items: center;
  > span {
    font-size: 14px;
    color: #fe019a;
    margin-left: 4px;
  }
}
