@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;800&display=swap');

body {
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  font-weight: 400;
}

.bold {
  font-weight: 900;
}

.h1 {
  font-size: 4rem;
}

.p {
  font-size: 1.66rem;
  line-height: 3rem;
}
